import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid } from "../../common/Grid";

import * as styles from "./StudioPostsPage.module.scss";

const StudioPostsPage = ({ studioPosts }) => {
  const [simple, setSimple] = useState(false);
  return (
    <>
      <div className={styles.projectHeader}>
        <h1 className="visually-hidden">Studio</h1>
      </div>
      <section className={styles.introduction}>
        <p>
          The studio is a place where work is developed, experiments are made,
          and prototypes are tested. Alistair McClymont's studio is in
          Edinburgh, UK, however work is often developed and tested in other
          locations.
        </p>
        <p>
          Day to day work can also be seen on{" "}
          <a
            href="https://www.instagram.com/alistairmcclymont/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram: @alistairmcclymont
          </a>
          .
        </p>
      </section>
      {studioPosts && (
        <Grid items={studioPosts.map(({ node }) => node)} type="studio" />
      )}
    </>
  );
};

export { StudioPostsPage };
